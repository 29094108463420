import { useEffect, useState } from "react";
import client from "../../client";
import DashboardLayout from "./layout";

const ProfilePropertyLayout = ({ data, property, title }) => {
  return (
    <>
      {data[property] && (
        <div className="flex items-center justify-between border-y border-y-[1px] p-3 bg-white ">
          <h2 className="font-medium text-base"> {title} </h2>
          <h3>{data[property]}</h3>
        </div>
      )}
    </>
  );
};

const Profile = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState({});
  const fetchProfile = async () => {
    try {
      //   setLoading(true);

      const response = await client({
        method: "GET",
        url: `/register/${token}`,
        // data: values,
      });

      setData(response.data.data);

      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      //   setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <DashboardLayout
      title="Profile"
      email={data.email}
      route="profile"
      status="user"
    >
      <section className="flex bg-white p-5 shadow-md rounded-md flex-col my-10 gap-8">
        <ProfilePropertyLayout
          data={data}
          property="fullName"
          title="Full Name"
        />
        <ProfilePropertyLayout data={data} property="email" title="Email" />
        <ProfilePropertyLayout data={data} property="address" title="Address" />
        <ProfilePropertyLayout
          data={data}
          property="phone"
          title="Phone Number"
        />
        <ProfilePropertyLayout data={data} property="level" title="Level" />
        <ProfilePropertyLayout
          data={data}
          property="university"
          title="University"
        />
        <ProfilePropertyLayout
          data={data}
          property="matricNumber"
          title="Matriculation Number"
        />
        <ProfilePropertyLayout
          data={data}
          property="department"
          title="Department"
        />
      </section>
    </DashboardLayout>
  );
};

export default Profile;
