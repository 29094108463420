import DashboardHeader from "./header";
import DashboardSidebar from "./sidebar";

const DashboardLayout = ({ children, email, status, route, title }) => {
  return (
    <main className="flex  bg-[#F4F4F5] overflow-hidden h-screen">
      <DashboardSidebar email={email} status={status} />
      <section className="overflow-y-scroll w-full flex flex-col gap-3   text=[#71717A]">
        <DashboardHeader route={route} />
        <main className="p-5 h-full">
          <h1 className="text-xl font-medium my-3">{title}</h1>
          {children}
        </main>
        <footer className="p-5 bg-white"></footer>
      </section>
    </main>
  );
};

export default DashboardLayout;
