import React, { useEffect, useState, useCallback, useContext } from 'react'
import { RiLogoutBoxRLine } from 'react-icons/ri'
import { GiArchiveRegister } from "react-icons/gi";
import { Link, useNavigate } from 'react-router-dom'
import storage from '../helpers/storage'

const Sidebar = () => {



    useEffect(() => {

        setActive(storage.fetchLegacy('sb-menu') ? storage.fetchLegacy('sb-menu') : 'tec-reg');

    }, [])

    const [active, setActive] = useState('')

    const navigate = useNavigate()

    const handleClick = (e, t, url, select) => {

        if (e) { e.preventDefault() }
        storage.keepLegacy('sb-menu', t)

        setActive(t)

        navigate(`/${url}`)


    }

    const logout = (e) => {
        if (e) e.preventDefault()
        storage.clearAuth()
        navigate('/admin-login')
    }


    const routes = [
        { title: 'Tec Registration', url: 'admin/tec-reg', icon: <GiArchiveRegister />, show: false },
    ]


    const renderedLinks = routes.map((route, i) => {
        return (

            <Link key={i} onClick={(e) => handleClick(e, route.title, route.url, route.show)} to={``} className={`flex items-center hover:bg-[#25AAE1] active:bg-[#25AAE1] active:text-white mb-5 px-5 py-4 rounded-lg ${active === route.title ? 'bg-[#25AAE1]' : 'bg-[#25AAE1]'}`}>
                <span className="text-white font-semibold stroke-2">{route.icon}</span>
                <span className="text-white font-semibold pl-3 text-sm capitalize">{route.title}</span>
            </Link>
        )
    })


    return (
        <>
            <div className="fixed h-screen w-[260px] shadow bg-gray-50 px-6 pt-8">

                <div className='flex flex-col items-center'>
                    <img src="../../../images/logo.png" width={50} alt="" />
                    <div className='text-center mx-auto mt-6'>

                        {/* <p className="text-black text-xl ps-4 font-bold">CAMS</p> */}
                        <p className="text-black text-lg font-bold capitalize">ADMIN Portal</p>
                        <p className="text-black text-xs ps-4 font-bold capitalize pt-3"> {storage.getUser()}</p>
                    </div>
                </div>

                <div className="pt-10 h-full">

                    {renderedLinks}

                    <Link onClick={(e) => logout(e)} to={``} className={`flex items-center hover:bg-[#25AAE1] active:bg-[#25AAE1] mb-5 px-5 py-4 rounded-lg `}>
                        <span className="text-black font-medium stroke-2"><RiLogoutBoxRLine /></span>
                        <span className="text-black font-medium pl-3 text-sm capitalize">Logout</span>
                    </Link>
                </div>


            </div>
        </>
    )
}

export default Sidebar