import React, { useState } from "react";
import EventEroimg from "../../assets/EvenHerotimg.jpg";
import HomeSubscribe from "../../components/subscribe/HomeSubscribe";
import { HiBadgeCheck } from "react-icons/hi";
import Footer from "../../components/Footer";
import client from "../../client";

const TecPage = () => {
  const [isAttend, setIsAttend] = useState(false);
  const [flag, setFlag] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [step, setStep] = useState(0);
  const [isSelectedtFaculty, setIsSelectedtFaculty] = useState(false);
  const [isDetails, setIsDetails] = useState(false);

  const [successMessage, setSuccessMessage] = useState('');

  const [disableBtn, setDisableBtn] = useState(false)

  const [state, setState] = useState({
    disabled: null,
    gender: null,
    faculty: "",
    name: "",
    matricNumber: "",
    phoneNumber: "",
    email: "",
    department: "",
  });

  function applyLoader() {
    // Apply styles to the body
    document.body.style = `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f3f3f3;
  `;

    // Create a div for the loader
    let loader = document.createElement('div');
    loader.id = 'loader';
    loader.style = `
    border: 16px solid #f3f3f3;
    border-top: 16px solid #0000FF; // Change this to the color you want
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  `;

    // Add the loader to the body
    document.body.appendChild(loader);

    // Add the spin animation
    let style = document.createElement('style');
    style.innerHTML = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;
    document.head.appendChild(style);
  }

  function removeLoader() {
    // Remove the loader from the body
    let loader = document.getElementById("loader");
    if (loader) {
      loader.remove();
    }

    // Reset the body's style
    document.body.style = "";
  }

  const submitToApi = async () => {
    try {
      // load the entire page
      document.body.style.cursor = "wait";
      // add a page loader
      // simple css loader
      // applyLoader()
      setLoading(true)

      const response = await client({
        method: "POST",
        url: `/tecRegistration`,
        data: state,
      });
      document.body.style.cursor = "default";
      console.log(response);
      if (response.status === 201) {
        setSuccessMessage(response.data.message)
        setStep(4)
      }
      // removeLoader();
      setLoading(false)
    } catch (error) {
      document.body.style.cursor = "default";
      console.log(error);
      // removeLoader();
      setLoading(false)
    }
  };
  const handleDetails = () => {
    setIsDetails(!isDetails);
  };
  const closeDetails = () => {
    setIsDetails(false);
  };

  const handleSelectedtFaculty = () => {
    setIsSelectedtFaculty(!false);
  };
  const closeSelectedtFaculty = () => {
    setIsSelectedtFaculty(false);
  };

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  const handleGenderChange = (value) => {
    setSelectedGender(value);
  };

  const handleAttend = () => {
    // setIsAttend(!isAttend);
    // setSelectedOption(null);
    setFlag(true)
    setStep(0)
  };

  const handleNext = () => {

    if (step === 0) {
      setStep(1)
    }

    else if (step === 1) {
      setStep(2)
    }

    else if (step === 2) {
      setStep(3)
    }

  }

  const closeAttend = () => {
    setIsAttend(false);
  };

  const handleDisabled = () => {
    setIsDisabled(!isDisabled);
    setSelectedGender(null);
  };

  const closeDisabled = () => {
    setIsDisabled(false);
  };

  const closeX = (e) => {

    if (e) e.preventDefault()

    if (step === 0) {
      setFlag(false)
    }

    if (step === 1) {
      setStep(0)
    }

    if (step === 2) {
      setStep(1)
    }

    if (step === 3) {
      setStep(2)
    }

  }

  const cancel = (e) => {
    setSuccessMessage('')
    setFlag(false)
    setStep(null)
  }

  return (
    <div className="mt-24 mx-auto overflow-x-hidden">
      <div

        className=" relative bg-no-repeat bg-cover bg-center h-screen grid items-center"
        style={{
          backgroundImage: `url('../../../images/event-14.JPG')`,
        }}
      >

        <div className="absolute w-full flex items-center inset-0 xl:h-screen md:h-screen lg:h-screen h-[500px] bg-gradient-to-r from-black/60 to-black/60">

          <div className="max-w-screen-xl grid mx-auto lg:pl-0 md:pl-0 pl-2  gap-5 xl:pt-0 lg:pt-0 md:pt-0 pt-12 text-white">
            <div className="w-full">
              <p className=" lg:text-[45px] md:text-[45px] text-[35px] font-bold font-satoshi">
                TEC UNILORIN ANNOUNCES  A DAY TRAINING BY THE LACANSTER UNIVERSITY,  UNITED KINGDOM ON CAREER AND ENTERPRISE DEVELOPMENT FOR FINAL YEAR STUDENT.
              </p>
            </div>
            
           
          </div>
        </div>

      </div>

      {/* Event */}
      <div className="lg:px-16 md:px-10 px-5 py-28 relative">
        <div>
          {/* <p className="lg:w-[500px] text-[24px]">
            Launch your career offer with University of Lancaster, United State of America
            through TEC Unilorin
          </p> */}
          <p className=" text-[28px] font-satoshi">Date: Thursday, 29th February, 2024</p>
          <p className=" text-[28px] font-satoshi">Venue: University Main Auditorium</p>
          <p className=" text-[28px] font-satoshi">Time: 12 noon prompt </p>

          <p className="text-[20px] pt-4">
            UK CPD certificate will be provided after the training
          </p>
        </div>
        <div className={`${step === 3 ? 'h-[330px]' : 'h-fit'} mt-4 py-10`}>
          <p>Will you be able to attend?</p>
          <div className="flex items-center gap-5 pt-5">
            <button
              onClick={handleAttend}
              className=" w-[120px] py-3 grid justify-center items-center bg-[#01b305] font-satoshi text-white rounded-md"
            >
              Yes
            </button>
            <button className=" w-[120px] py-3 grid justify-center items-center bg-[#01b305] font-satoshi text-white rounded-md">
              No
            </button>
          </div>

          {isAttend && (
            <div className=" absolute top-0 left-0 w-full h-full py-10 grid justify-center items-center bg-[#01b305] bg-opacity-40">
              <div
                // data-aos="fade-left"
                // data-aos-duration="3000"
                className=" w-[300px] bg-white h-full px-8 py-10 rounded-md grid  "
              >
                <div className=" flex justify-end items-center">
                  <p
                    onClick={closeAttend}
                    className=" cursor-pointer font-satoshi"
                  >
                    X
                  </p>
                </div>
                <div className=" ">
                  <p className=" text-[20px] font-satoshi">Are you Disabled</p>
                  <div className="flex items-center gap-5 pt-2">
                    <div className=" flex items-center gap-2">
                      <input
                        id="yes"
                        name="option"
                        checked={selectedOption === "yes"}
                        onChange={() => {
                          handleRadioChange("yes");
                          setState({ ...state, disabled: true });
                        }}
                        className=" mt-1"
                        type="radio"
                      />
                      <p>Yes</p>
                    </div>
                    <div className=" flex items-center gap-2">
                      <input
                        id="no"
                        name="option"
                        checked={selectedOption === "no"}
                        onChange={() => {
                          handleRadioChange("no");
                          setState({ ...state, disabled: false });
                        }}
                        className=" mt-1"
                        type="radio"
                      />
                      <p>No</p>
                    </div>
                  </div>
                </div>

                <div className=" flex justify-center items-center mt-4">
                  <button
                    onClick={handleDisabled}
                    className=" w-[120px] py-3 grid justify-center items-center bg-[#01b305] font-satoshi text-white rounded-md"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}

          {
            flag &&

            <div className="absolute top-0 left-0 z-20 w-full h-full py-10 grid justify-center items-center bg-[#01b305] bg-opacity-40">

              <div

                className={`${(step === 3 || step === 4) ? 'lg:w-[600px] md:w-[600px] xl:w-[600px] w-fit py-5' : 'w-[300px]'} pt-5 pb-5  bg-white h-fit px-8  rounded-lg grid`}>


                <div className=" flex justify-end items-center mb-6">

                  <span onClick={(e) => closeX(e)} className="text-2xl cursor-pointer h-8 w-8 pb-1 flex items-center justify-center rounded-full text-[#febd01] hover-text-blue-400 bg-blue-300 hover:bg-blue-200 transition-all ease-in-out duration-300">&times;</span>


                </div>

                {
                  step === 0 &&
                  <>
                    <div

                      className=" mb-12">
                      <p className=" text-[20px] font-satoshi">Are you Disabled</p>
                      <div className="flex items-center gap-5 pt-2">
                        <div className=" flex items-center gap-2">
                          <input
                            id="yes"
                            name="option"
                            checked={selectedOption === "yes"}
                            onChange={() => {
                              handleRadioChange("yes");
                              setState({ ...state, disabled: true });
                            }}
                            className=" mt-1"
                            type="radio"
                          />
                          <p>Yes</p>
                        </div>
                        <div className=" flex items-center gap-2">
                          <input
                            id="no"
                            name="option"
                            checked={selectedOption === "no"}
                            onChange={() => {
                              handleRadioChange("no");
                              setState({ ...state, disabled: false });
                            }}
                            className=" mt-1"
                            type="radio"
                          />
                          <p>No</p>
                        </div>
                      </div>
                    </div>

                    <div className=" flex justify-end items-center mt-4">
                      <button
                        onClick={handleNext}
                        className=" w-[120px] py-3 grid justify-center items-center bg-[#01b305] font-satoshi text-white rounded-md"
                      >
                        Next
                      </button>
                    </div>
                  </>
                }

                {
                  step === 1 &&
                  <>
                    <div className="mb-12">
                      <p className=" text-[20px] font-satoshi">Gender</p>
                      <div className="flex items-center gap-5 pt-5">
                        <div className=" flex items-center gap-2">
                          <input
                            id="male"
                            name="gender"
                            value="male"
                            checked={selectedGender === "male"}
                            onChange={() => {
                              handleGenderChange("male");
                              setState((s) => ({ ...s, gender: "male" }));
                            }}
                            className="mt-1"
                            type="radio"
                          />
                          <label htmlFor="male">Male</label>
                        </div>
                        <div className=" flex items-center gap-2">
                          <input
                            id="female"
                            name="gender"
                            value="female"
                            checked={selectedGender === "female"}
                            onChange={() => {
                              handleGenderChange("female");
                              setState((s) => ({ ...s, gender: "female" }));
                            }}
                            className="mt-1"
                            type="radio"
                          />
                          <label htmlFor="female">Female</label>
                        </div>
                      </div>
                    </div>

                    <div className=" flex justify-end items-center mt-4">
                      <button
                        onClick={handleNext}
                        className=" w-[120px] py-3 grid justify-center items-center bg-[#01b305] font-satoshi text-white rounded-md"
                      >
                        Next
                      </button>
                    </div>
                  </>
                }

                {
                  step === 2 &&
                  <div

                  >
                    <p className=" text-[20px] font-bold">Faculty</p>
                    <div className=" pt-2 overflow-y-scroll h-[150px] text-gray-400">
                      <select
                        onChange={(e) => {
                          setState({ ...state, faculty: e?.target?.value });
                          setStep(3);
                        }}
                        className="cursor-pointer hover:text-[#febd01] border rounded-md px-3 py-3 w-full"
                      >
                        <option value="">None</option>

                        <option value="Agriculture">Agriculture</option>
                        <option value="Medicine">Medicine</option>
                        <option value="Basic Medical">Basic Medical</option>
                        <option value="Basic Science">Basic Science</option>
                        <option value="CIS">CIS</option>
                        <option value="Engineering">Engineering</option>
                        <option value="Life Sciences">Life Sciences</option>
                        <option value="Veterinary">Veterinary</option>
                        <option value="Pharmacy">Pharmacy</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                  </div>
                }

                {
                  step === 3 &&
                  <div className="px-5">
                    <p className=" text-[20px] font-bold">Student Details</p>
                    <div className=" pt-2">
                      <form action="">
                        <div className=" grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-x-5 ">
                          {/* Name */}
                          <div className="w-full mt-2">
                            <label className=" font-satoshi" htmlFor="">
                              Name
                            </label>
                            <input
                              className=" h-[48px] mt-3 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                              placeholder="Name"
                              type="text"
                              name=""
                              id=""
                              onChange={(e) =>
                                setState({ ...state, name: e?.target?.value })
                              }
                            />
                          </div>
                          {/* Matric Number */}
                          <div className="w-full   mt-3">
                            <label className=" font-satoshi" htmlFor="">
                              Matric Number
                            </label>
                            <input
                              className=" h-[48px] mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                              placeholder="Matric Number"
                              type="text"
                              name=""
                              id=""
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  matricNumber: e?.target?.value,
                                })
                              }
                            />
                          </div>

                          {/*  */}

                          {/* Phone Number */}
                          <div className="w-full mt-5">
                            <label className=" font-satoshi" htmlFor="">
                              Phone Number
                            </label>
                            <input
                              className=" h-[48px] mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                              placeholder="Phone Number"
                              type="text"
                              name=""
                              id=""
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  phoneNumber: e?.target?.value,
                                })
                              }
                            />
                          </div>
                          {/* Email */}
                          <div className="w-full mt-5">
                            <label className=" font-satoshi" htmlFor="">
                              Email
                            </label>
                            <input
                              className=" h-[48px] mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                              placeholder="Email"
                              type="email"
                              name=""
                              id=""
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  email: e?.target?.value,
                                })
                              }
                            />
                          </div>

                          <div className="w-full mt-5">
                            <label className=" font-satoshi" htmlFor="">
                              Department
                            </label>
                            <input
                              className=" h-[48px] mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                              placeholder="Department"
                              type="text"
                              name=""
                              id=""
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  department: e?.target?.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className=" flex justify-end items-center mt-8 mb-4">
                      <button
                        className=" w-[180px] py-5 grid justify-center items-center bg-[#01b305] hover:bg-blue-700 font-satoshi text-white transition-all ease-in-out duration-300 rounded-md text-center"
                        onClick={() => {
                          console.log(state);
                          submitToApi();
                        }}
                      >

                        {loading ? <span className="vp-loader sm white"></span> : <span className='font-bold fs-15 text-white'>Submit</span>}

                        {/* Submit */}
                      </button>
                    </div>
                  </div>
                }

                {
                  step === 4 &&
                  <div className="flex flex-col items-center py-5">
                    <HiBadgeCheck className="text-7xl text-green-600" />
                    <h2 className="pt-6 text-2xl pb-5 font-semibold w-[250px] text-center">{successMessage}</h2>
                    <button
                      className=" w-[180px] py-4 grid justify-center mt-4 items-center bg-[#01b305] hover:bg-blue-700 font-satoshi text-white transition-all ease-in-out duration-300 rounded-md text-center"
                      onClick={(e) => cancel(e)}
                    >

                      <span className='font-bold fs-15 text-white'>Okay</span>

                      {/* Submit */}
                    </button>
                  </div>
                }

              </div>

            </div>
          }

        </div>
        {isDisabled && (
          <div className=" absolute top-0 left-0 w-full h-full py-10 grid justify-center items-center bg-[#01b305] bg-opacity-40">
            <div

              className=" w-[300px] bg-white h-full px-8 py-10 rounded-md grid "
            >
              <div className=" flex justify-end items-center">
                <p
                  onClick={closeDisabled}
                  className=" cursor-pointer font-satoshi"
                >
                  X
                </p>
              </div>
              <div>
                <p className=" text-[20px] font-satoshi">Gender</p>
                <div className="flex items-center gap-5 pt-5">
                  <div className=" flex items-center gap-2">
                    <input
                      id="male"
                      name="gender"
                      value="male"
                      checked={selectedGender === "male"}
                      onChange={() => {
                        handleGenderChange("male");
                        setState((s) => ({ ...s, gender: "male" }));
                      }}
                      className="mt-1"
                      type="radio"
                    />
                    <label htmlFor="male">Male</label>
                  </div>
                  <div className=" flex items-center gap-2">
                    <input
                      id="female"
                      name="gender"
                      value="female"
                      checked={selectedGender === "female"}
                      onChange={() => {
                        handleGenderChange("female");
                        setState((s) => ({ ...s, gender: "female" }));
                      }}
                      className="mt-1"
                      type="radio"
                    />
                    <label htmlFor="female">Female</label>
                  </div>
                </div>
              </div>

              <div className=" flex justify-center items-center mt-4">
                <button
                  onClick={handleSelectedtFaculty}
                  className=" w-[120px] py-3 grid justify-center items-center bg-[#01b305] font-satoshi text-white rounded-md"
                >
                  Next
                </button>
              </div>
            </div>

            {isSelectedtFaculty && (
              <div className=" absolute top-0 left-0 w-full h-full py-10 grid justify-center items-center bg-[#01b305] bg-opacity-40">
                <div

                  className=" w-[300px] bg-white h-full px-8 py-5 rounded-md "
                >
                  <div className=" flex justify-end items-center">
                    <p
                      onClick={closeSelectedtFaculty}
                      className=" cursor-pointer font-satoshi"
                    >
                      X
                    </p>
                  </div>
                  <p className=" text-[20px] font-bold">Faculty</p>
                  <div className=" pt-2 overflow-y-scroll h-[250px] text-gray-400">
                    <select
                      onChange={(e) => {
                        setState({ ...state, faculty: e?.target?.value });
                        handleDetails();
                      }}
                      className="cursor-pointer hover:text-[#febd01]"
                    >
                      <option value="">None</option>

                      <option value="Agriculture">Agriculture</option>
                      <option value="Medicine">Medicine</option>
                      <option value="Basic Medical">Basic Medical</option>
                      <option value="Basic Science">Basic Science</option>
                      <option value="CIS">CIS</option>
                      <option value="Engineering">Engineering</option>
                      <option value="Life Sciences">Life Sciences</option>
                      <option value="Veterinary">Veterinary</option>
                      <option value="Pharmacy">Pharmacy</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                {isDetails && (
                  <div className=" absolute top-0 left-0 w-full z-20 h-full py-5 grid justify-center items-center bg-[#01b305] bg-opacity-40">
                    <div

                      className=" lg:w-[600px] bg-white h-full px-8 py-5 rounded-md grid"
                    >
                      <div className=" flex justify-end items-center">
                        <p
                          onClick={closeDetails}
                          className=" cursor-pointer font-satoshi"
                        >
                          X
                        </p>
                      </div>
                      <p className=" text-[20px] font-bold">Student Details</p>
                      <div className=" pt-2">
                        <form action="">
                          <div className=" grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-x-5 ">
                            {/* Name */}
                            <div className="w-full mt-2">
                              <label className=" font-satoshi" htmlFor="">
                                Name
                              </label>
                              <input
                                className=" h-[34px]  mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                                placeholder="Name"
                                type="text"
                                name=""
                                id=""
                                onChange={(e) =>
                                  setState({ ...state, name: e?.target?.value })
                                }
                              />
                            </div>
                            {/* Matric Number */}
                            <div className="w-full   mt-2">
                              <label className=" font-satoshi" htmlFor="">
                                Matric Number
                              </label>
                              <input
                                className=" h-[34px] mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                                placeholder="Matric Number"
                                type="text"
                                name=""
                                id=""
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    matricNumber: e?.target?.value,
                                  })
                                }
                              />
                            </div>

                            {/*  */}

                            {/* Phone Number */}
                            <div className="w-full mt-2">
                              <label className=" font-satoshi" htmlFor="">
                                Phone Number
                              </label>
                              <input
                                className=" h-[34px] mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                                placeholder="Phone Number"
                                type="text"
                                name=""
                                id=""
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    phoneNumber: e?.target?.value,
                                  })
                                }
                              />
                            </div>
                            {/* Email */}
                            <div className="w-full mt-2">
                              <label className=" font-satoshi" htmlFor="">
                                Email
                              </label>
                              <input
                                className=" h-[34px] mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                                placeholder="Email"
                                type="email"
                                name=""
                                id=""
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    email: e?.target?.value,
                                  })
                                }
                              />
                            </div>

                            <div className="w-full mt-2">
                              <label className=" font-satoshi" htmlFor="">
                                Department
                              </label>
                              <input
                                className=" h-[34px] mt-2 outline-none px-3 w-full rounded-md border-[0.5px] border-gray-400"
                                placeholder="Department"
                                type="text"
                                name=""
                                id=""
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    department: e?.target?.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className=" flex justify-center items-center mt-4">
                        <button
                          className=" w-[120px] py-3 grid justify-center items-center bg-[#01b305] font-satoshi text-white rounded-md"
                          onClick={() => {
                            console.log(state);
                            submitToApi();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Subscribe to Our Newsletter */}
      <div
        className=" lg:block md:block hidden relative z-10"

      >
        <HomeSubscribe />
      </div>
      {/* footer */}
      <div>
        <Footer />
      </div>
    </div >
  );
};

export default TecPage;
