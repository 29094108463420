const DashboardSidebar = ({ email = "admin@asbe.com", status = "admin" }) => {
  return (
    <aside className="bg-[#01b305] h-full md:flex hidden flex-col gap-5 flex-col-reverse p-6 text-white">
      <div className="">
        <h2>{status === "admin" ? "Admin Name" : "User name"}</h2>
        <h4 className="text-light">{email}</h4>
      </div>
    </aside>
  );
};

export default DashboardSidebar;
