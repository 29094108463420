import React, { useContext, useEffect, useState } from "react";


const TopBar = ({ header, text, show }) => {


    useEffect(() => {

    }, []);


    return (
        <div className="pl-6 pt-10 pb-2 border-b-2 border-gray-200 bg-gray-50 flex justify-between">


            <div className="flex items-center mb-1 pr-12">

                <p className="text-lg font-medium">{header}</p>

            </div>


        </div>

    );

}

export default TopBar;
