const ModalLayout = ({ children, setOpen, open }) => {
  return (
    <>
      {open && (
        <main className=" modal-backdrop ">
          <section
            className={`  max-w-[100vw]  z-[1000]  bg-[#F8FDF9] md:w-auto w-full fixed top-1/2 left-1/2 rounded-md max-h-[96vh]  -translate-x-1/2  -translate-y-1/2 p-2 border-2`}
          >
            <header className="mb-2 flex flex-row-reverse justify-between ">
              <h1 className="cursor-pointer" onClick={() => setOpen(!open)}>
                X
              </h1>
              {/* <MdClose className="cursor-pointer" /> */}
            </header>

            <main className=" m-5 ">{children}</main>
          </section>
        </main>
      )}
    </>
  );
};

export default ModalLayout;
