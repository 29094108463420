import { NavLink } from "react-router-dom";

const DashboardHeader = ({ route }) => {
  return (
    <header className="bg-white flex items-center justify-between p-5">
      <nav>{route}</nav>
      <NavLink to="/">Back</NavLink>
    </header>
  );
};

export default DashboardHeader;
