import React, { useState, useEffect } from "react";

import Sidebar from "../Sidebar";
import TopBar from "../Topbar";

const DashboardLayout = ({ Component, header, text }) => {

    useEffect(() => {
    }, []);

    return (
        <>
            <div className="flex h-screen">
                <Sidebar />

                <main className={`pl-[260px] w-screen h-screen dashboard`}>
                    <div className={`w-full h-screen comp`}>
                        <TopBar header={header} text={text} />
                        <div className="pl-6 pt-6">
                            <Component />

                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default DashboardLayout;
