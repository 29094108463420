import React from "react";

const Footer = () => {
  return (
    <div className=" bg-[#e6ffe6] text-black font-Satoshi pt-16 pb-12">
      <div className="md:px-10 lg:px-16 px-8 w-full">
        <div className=" lg:flex md:grid md:grid-cols-2 md:gap-10 grid justify-between">
          <div className=" grid gap-3  mb-5">
            <div className="  font-semibold flex items-center">
              <p className=" font-bold text-[24px] text-[#007921] font-roboto pt-2">ASBE is</p>
            </div>
            <div className=" w-[350px] mb-6">
              <p>
                A support and motivational organisation made up of
                intellectuals, birth to raise vibrant and resilient
                entrepreneurs, innovators, t echnology and skills developers
                across higher institutions of learning in Africa{" "}
              </p>
            </div>
            <div className=" flex gap-3 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="currentColor"
                style={{ color: "#1877f2" }}
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="currentColor"
                style={{ color: "#1da1f2" }}
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="currentColor"
                style={{ color: "#0077b5" }}
                viewBox="0 0 24 24"
              >
                <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="currentColor"
                style={{ color: "#ff0000" }}
                viewBox="0 0 24 24"
              >
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
              </svg>
            </div>
          </div>

          <div className=" flex lg:gap-20 md:gap-10 md:ml-auto  gap-10 lg:my-0 md:my-10 my-10">
            <ul className="flex flex-col space-y-4  font-roboto">
              <p className=" text-[16px] font-bold mb-4 text-[#013d02]">ASBE</p>
              <li className=" text-sm">About Us</li>
              <li className=" text-sm">Event</li>
              <li className=" text-sm">Testimonials</li>
              <li className=" text-sm">Blogs</li>
            </ul>
            <ul className="flex flex-col space-y-4">
              <p className=" text-[16px] font-bold mb-4 text-[#013d02]">Support</p>
              <li className=" text-sm">Careers</li>
              <li className=" text-sm">Resources</li>
              <li className=" text-sm">Faqs</li>
              <li className=" text-sm">Privacy Policy</li>
              <li className=" text-sm">Careers</li>
            </ul>

            <ul className="flex flex-col space-y-4">
              <p className=" text-[16px] font-bold mb-4 text-[#013d02]">Get In Touch</p>
              <li className=" text-sm flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>

                <p className="pl-2">asbeglobalinnovation@gmail.com</p>
              </li>
              <li className=" text-sm">+234 803 821 7248</li>
              <li className=" text-sm ">
                <p>TEC Building, University of Ilorin, Ilorin, Nigeria</p>
              </li>
            </ul>

          </div>
        </div>
        <div className="h-[0.3px] w-full border-[0.1px] border-[#beff99] my-5"></div>
        <div className="w-full ">
          <div className="flex w-7/12 justify-between">
            <p className=" text-[15px]">
              Copyright © 2023 ASBE. All rights reserved.
            </p>
            <p className=" text-[15px] ">Moulding African Entrepreneurs for Global Impacts.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
