import axios from "axios";
import { toast } from "react-toastify";

const ErrorNotification = (err) => {
  console.error(err);
  return toast.error(err);
};

const SuccessNotification = (res) => toast.success(res);
// https://city.herokuapp.com/api/v1
// http://localhost:5010/api/v1/tecRegistration
// https://asbe-5d42cba64459.herokuapp.com/api/v1
export const instance = axios.create({
  baseURL: "https://city.herokuapp.com/api/v1",
  // baseURL: process.env.REACT_APP_BASE_URL,
});

const client = async ({ ...options }) => {
  const onSuccess = (response) => {
    console.log(response);
    // eslint-disable-next-line no-unused-expressions
    options.method === "GET"
      ? ""
      : SuccessNotification(response?.data?.message);
    return response;
  };
  const onError = (err) => {
    console.log(err);
    if (err.message === "Network Error") {
      return ErrorNotification("Please check your internet connection");
    }
    if (options.method === "get") {
      return err;
    }

    console.log(err?.response?.data?.message);
    ErrorNotification(err?.response?.data?.message);
    throw err;
  };

  return instance(options).then(onSuccess).catch(onError);
};

export default client;
