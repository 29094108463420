import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
// import Home from "./pages/home-page";
// import About from "./pages/about-page";
// import NotFound from "./pages/page-not-found";
// import Events from "./pages/event-page";
// import Testimonials from "./pages/testimonials-page";
// import SignUp from "./pages/signup-page";
// import LoginPage from "./pages/login-page";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
// import 'swiper/css/navigation'
// import 'swiper/css/pagination'

import Layout from "./components/layout";

import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Dashboard from "./pages/dashboard";
import TecPage from "./pages/tec-page";
import Profile from "./pages/dashboard/profile";
import ProtectedRoute from "./protectedRoute";
import MainLoader from "./components/MainLoader";
import DashboardLayout from "./components/layouts/DashboardLayout";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const Home = React.lazy(() => import('./pages/home-page'))
const Cerificate = React.lazy(() => import('./pages/home-page/certificate'))
const Workshop = React.lazy(() => import('./pages/home-page/workshop'))
const About = React.lazy(() => import('./pages/about-page'))
const TecRegDB = React.lazy(() => import('./pages/admin/TecReg'))
const Events = React.lazy(() => import('./pages/event-page'))
const Testimonials = React.lazy(() => import('./pages/testimonials-page'))
const SignUp = React.lazy(() => import('./pages/signup-page'))
const LoginPage = React.lazy(() => import('./pages/login-page'))
const AdminLogin = React.lazy(() => import('./pages/admin/Login'))
const NotFound = React.lazy(() => import('./pages/page-not-found'))

function App() {

  const queryClient = new QueryClient()

  return (
    <>
      <ToastContainer transition={Zoom} autoClose={2000} />

      <QueryClientProvider client={queryClient}>

        <Router>
          {/* <Navbar /> */}

          <Suspense fallback={MainLoader()}>
            <Routes>
              <Route
                path="/about"
                exact
                element={
                  <Layout>
                    <About />
                  </Layout>
                }
              />
              <Route path="/events" exact element={<Layout><Events /></Layout>} />
              <Route path="/events/certificate" exact element={<Layout> <Cerificate /> </Layout>} />
              <Route
                path="/events/workshop"
                exact
                element={
                  <Layout>
                    <Workshop />
                  </Layout>
                }
              />
              <Route
                path="/testimonials"
                exact
                element={
                  <Layout>
                    <Testimonials />
                  </Layout>
                }
              />
              <Route
                path="/tec"
                exact
                element={
                  <Layout>
                    <TecPage />
                  </Layout>
                }
              />
              <Route
                path="/signup"
                exact
                element={
                  <Layout>
                    <SignUp />
                  </Layout>
                }
              />
              <Route
                path="/login"
                exact
                element={
                  <Layout>
                    <LoginPage />
                  </Layout>
                }
              />
              <Route path="/dashboard" Component={Dashboard} />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/not-found"
                element={
                  <Layout>
                    <NotFound />
                  </Layout>
                }
              />

              <Route
                path="/"
                exact
                element={
                  <Layout>
                    <Home />
                  </Layout>
                }
              />
              <Route path="*" element={<Navigate to="/not-found" />} />
              <Route path="/about-view-all" element={<Navigate to="/about#aboutImagesSection" />} />
              <Route path="/admin-login" element={<AdminLogin />} />
              <Route
                path="/admin/tec-reg"
                element={
                  <DashboardLayout
                    Component={TecRegDB}
                    header="TEC REGISTRATION"

                  />
                }
              />
            </Routes>

          </Suspense>

        </Router>

        {/* <ReactQueryDevtools initialIsOpen={false} position='right' /> */}

      </QueryClientProvider>
    </>
  );
}

export default App;
