import { useEffect, useState } from "react";
import client from "../../client";
import DetailsModal from "./details";
import DashboardLayout from "./layout";

const Dashboard = () => {
  const [users, setUsers] = useState([]);

  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({});

  const viewDetails = (data) => {
    setOpen(!open);
    setDetails(data);
  };

  const getRegisteredUsers = async () => {
    try {
      const response = await client({
        method: "GET",
        url: "/register",
      });
      if (response.status === 200) {
        setUsers(response.data.data.docs);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  console.log(users);

  useEffect(() => {
    getRegisteredUsers();
  }, []);

  const renderUsers = users?.map((data, i) => {
    return (
      <tr
        className="odd:bg-slate-100 border-b border-1 border-b- cursor-pointer my-3 mx-4"
        key={i}
      >
        {/* <td className="p-4 ">
          <input type="checkbox" name="" id="" />
        </td> */}
        <td className="py-4 ">{data.fullName}</td>

        <td className="py-4">{data.email}</td>
        <td className="py-4">{data.phone}</td>
        <td className="py-4 ">
          <label
            className=" px-5 py-2 rounded-md border text-[#166534] bg-[#DCFCE7]"
            htmlFor="
          "
          >
            active
          </label>
        </td>
        <td className="py-4">
          <label
            onClick={() => viewDetails(data)}
            className="border-[1px] px-10 py-2 text-[#febd01] border-primary rounded-md"
            htmlFor="
            "
          >
            View Details
          </label>
        </td>
      </tr>
    );
  });
  return (
    <>
      <DetailsModal details={details} open={open} setOpen={setOpen} />

      <DashboardLayout route="Dashboard > Users" title="All Users">
        <main className="p-5 h-full">
          {/* <h1 className="text-xl font-medium my-3">All Users</h1> */}
          <div className="">
            <input
              className="w-full bg-transparent border border-2 p-2 my-2 rounded-md border[#ccc]"
              type="search"
              name=""
              id=""
            />
          </div>
          <section className="container overflow-x-scroll mx-auto">
            <table className="min-w-full  ">
              <thead className="">
                <tr className="my-3 font-medium">
                  <td className="p-4">Full Name</td>
                  <td className="p-4">Email Address</td>
                  <td className="p-4">Phone Number</td>
                  <td className="p-4">Status</td>
                  <td className="p-4">Action</td>
                </tr>
              </thead>
              <tbody className="w-full">{renderUsers}</tbody>
            </table>
          </section>
        </main>
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
