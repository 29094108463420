import ModalLayout from "../../components/modal";

const TextContent = ({ title, text }) => {
  return (
    <div className="flex items-center gap-3 my-2">
      <h1 className="font-medium">{title}</h1> : <h3>{text}</h3>
    </div>
  );
};

const DetailsModal = ({ details, open, setOpen }) => {
  console.log({ details });
  return (
    <ModalLayout open={open} setOpen={setOpen}>
      <h1 className="text-[#febd01] font-medium">User Details</h1>
      <TextContent title="Full Name" text={details.fullName} />
      <TextContent title="Email" text={details.email} />
      <TextContent title="Level" text={details.level} />
      <TextContent title="Matic Number" text={details.matricNumber} />
      <TextContent title="Department" text={details.department} />
      <TextContent title="University" text={details.university} />
    </ModalLayout>
  );
};

export default DetailsModal;
