import React from 'react'

const MainLoader = () => {

    return (
        <div>
            <div className="flex items-center justify-center h-screen flex-col bg-[#080808] w-full">

                <div className="suspense_image text-center">
                    <span className='loader white md'></span>
                </div>

            </div>
        </div>
    )

}

export default MainLoader